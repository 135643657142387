body {
  margin: 0;
  padding: 0;
  font-family: "Inter", sans-serif !important;
  background-color: #0b0c0f;
  color: #ffffff;
  overflow-x: hidden;
}

button,
input,
optgroup,
select,
textarea {
  font-family: "Inter", sans-serif !important;
}

a {
  color: #fbea78;
  text-decoration: none;
}

a:hover {
  color: #fbea78;
  text-decoration: underline;
}

/* Custom Scrollbar */
*::-webkit-scrollbar {
  width: 6px;
  height: 5px;
}

*::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

*::-webkit-scrollbar-thumb {
  background-color: #f0c96c;
}

.brand_logo {
  width: 165px;
} 
.menu_show {
  background: #363cee;
  display: inline-block !important;
  position: fixed;
  right: -100%;
  height: 100%;
  z-index: 10;
  top: 0;
  transition: .5s;
}

.main_navbar {
  background-color: #00162400 !important;
  /* border-bottom: 1px solid #0c4751; */
  z-index: 10;
  transition: transform 0.3s;
  justify-content: flex-end;
  width: 100%;
  padding-top:20px ;
  padding-bottom: 20px;
}

.main_navbar.fixed_navbar {
  position: sticky;
  width: 100%;
  top: -40px;
  background-color: #0f1014 !important;
  box-shadow: 0 0 10px 5px rgb(4 4 4 / 40%);
  z-index: 999;
  transform: translateY(40px);
  justify-content: flex-end;
}
.main_navbar .navbar-brand{
  display: none;
}
.navbar_right {
  display: flex;
  align-items: center;
}

.navbar_right li a {
  color: #5c7086;
  font-size: 16px;
  font-weight: 500;
  text-transform: uppercase;
  text-decoration: none;
  cursor: pointer;
  position: relative;
}

.navbar_right li a:hover,
.navbar_right li a.active {
  color: #fff !important;
}

/* .navbar_right li a.active::after,
.navbar_right li a:hover::after {
  content: "";
  background: #02cce1;
  width: 90%;
  height: 1px;
  position: absolute;
  bottom: -16px;
  left: 0;
  right: 0;
  margin: 0 auto;
} */
.main_navbar .dropdown-toggle {
  white-space: nowrap;
  width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
  position: relative;
}
.main_navbar .dropdown-toggle::after {
  position: absolute;
  right: 11px;
  top: 19px;
}
.navbar_right .primary_btn {
  font-size: 16px;
  padding: 10px 25px;
  margin-right: 30px;
}
.primary_btn:hover {
  color: #030303 !important;
}
.dropdown .dropdown-menu {
  background: black;

}
.dropdown .dropdown-menu  a{
  color: #fff;
  
  padding: 10px !important;
  font-size: 16px !important;
  white-space: normal;
}
.dropdown .dropdown-item:active,
.dropdown .dropdown-item:hover,
 .dropdown .dropdown-item:focus{
  background-color: #c1833f;
}
.dropdown .dropdown-menu p{
  color: #fff;
  padding: 10px;
  margin-bottom: -5px;
  padding-bottom: 0;
}
/* 
.navbar_right .primary_btn:after,
.navbar_right .primary_btn:before {
  content: " ";
  width: 30px;
  height: 30px;
} */
.sidebar .dropdown-toggle{
  position: relative;
  padding-right: 18px !important;
}
.sidebar .dropdown-toggle::after{
  display: inline-block;
  width: unset;
  height: unset;
  position: absolute;
  right: 6px;
  top: 17px;
 }
.sidebar  .dropdown button{
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding: 8px;
  margin-top: 15px;
  width: 170px;
  margin-left: 15px;
}
.sidebar .dropdown .dropdown-menu a{
  white-space: normal;
}
.page_header {
  position: relative;
  padding: 150px 0;
  display: flex;
  /* align-items: center; */
  justify-content: center;
  background: url("../src/assets/images/platinum/banbg.png") no-repeat center;
  background-size: 100% 100%;
  background-size: cover;
  margin-top: -96px;
  overflow: visible !important;
  /* height: 1072px; */

}
.page_header::before {
  content: "";
  position: absolute;
  background: url("../src/assets/images/platinum/ban_rotate_big.png") no-repeat center;
  width: 231px;
  height: 210px;
  bottom: 0;
  right: 29px;
  animation: rotate 5s infinite linear; 
  animation-duration: 18s;
}

@keyframes rotate {
  to {
    transform: rotate(360deg);
   }
}
.page_header::after{
  content: "";
  position: absolute;
  background: url("../src/assets/images/platinum/ban_rotate.png") no-repeat center;
  width: 135px;
  height: 126px;
  top: 38%;
  right: 130px;
  animation: rotate 5s infinite linear; 
  animation-duration: 12.5s;
}


.page_header>.container>.row:nth-child(1) {
  z-index: 10;
  position: relative
}

.timer_panel {
  display: flex;
  justify-content: center;
  text-align: center;
  margin: 10px 0 20px;
}

.timer_time {
  font-size: 36px;
  text-align: center;
  font-weight: 700;
  color: #1d1f24;
  background: url("../src/assets/images/platinum/ban_num.png") no-repeat center;
  background-size: contain;
  width: 88px;
  height: 88px;
  display: flex;
  align-items: center;
  justify-content: center;
}

span.timer_label {
  display: block;
  text-align: center;
  font-size: 16px;
  color: #707883;
  margin-top: 5px;
}

span.timer_dots {
  font-size: 30px;
  color: #707883;
  line-height: 82px;
}

.timer_panel>span {
  margin: 5px 5px 0 0;
}

.time_blw {
  display: flex;
  justify-content: center;
}

.time_blw p {
  color: #fff !important;
}

.time_blw span {
  color: #fbea78;
  padding-left: 15px;
}

.time_blw .line {
  color: #707883;
  padding: 0 15px;
}

.presale_box {
  border-radius: 30px;
  padding: 95px 73px;
  text-align: center;
  background: url("../src/assets/images/platinum/ban_border.png") no-repeat center;
  background-size: 100% 100%;
  position: relative;
  z-index: 1;
}
.presale_box::before{
  content: "";
  position: absolute;
  background: url("../src/assets/images/platinum/banimg5.png") no-repeat center;
  left: -40px;
  top: 40%;
  width: 101px;
  height: 101px;
  animation: square1 18s ease infinite;
  animation: square1 18s cubic-bezier(0.18, 0.18, 0.82, 0.78) infinite;
  
  animation: rotate 5s infinite linear;
}
/* @keyframes mymove {
  0% {top: 0%;}
  20% {top: 90%;}
  50% {top: 90%;left: 0;}
  70% {top: 90%;left: 90%;}
  80% {top: 0%;left: 90%;}
  100% {top: 0%;left: 0%;}

} */
/* @keyframes square {
  0%, 100% {
    transform: translate(-100%, -100%);
  }
  25% {
    transform: translate(100%, -100%);
  }
  50% {
    transform: translate(100%, 100%);
  }
  75% {
    transform: translate(-100%, 100%);
  }
} */
@keyframes square1 {
  0%    {top: 0; left: 90%}
  25%   {top: 90%; left: 90%;}
  50%   {top: 90%; left: -5%;}
  75%  {top: 0px; left: -5%;}
  100%    {top: 0; left: 90%}
}

.presale_box_inner,
.presale_box_outer {
  height: 100%;
}
.presale_box_inner::before,
.presale_box_inner::after,
.presale_box_outer::before,
.presale_box_outer::after {
  content: "";
  background: url("../src/assets/images/presale_box_corner.png");
  width: 45px;
  height: 48px;
  position: absolute;
  
  animation: rotate 5s infinite linear !important;
}
.presale_box_inner::before {
  background: url("../src/assets/images/platinum/banimg1.png") no-repeat center;
  width: 100px;
  height: 101px;
  right: 0;
  top: -25px;
  left: -65px;
  animation: square2 18s cubic-bezier(0.18, 0.18, 0.82, 0.78) infinite;
  animation: rotate 6s infinite linear !important;
}
@keyframes square2 {
  0%    {top: 0; left: -5%}
  25%   {top: 0%; left: 90%;}
  50%   {top: 90%; left: 90%;}
  75%  {top: 90%; left: -5%;}
  100%    {top: 0; left: -5%}
}



.presale_box_inner::after {
  background: url("../src/assets/images/platinum/banimg2.png") no-repeat center;
  top: 135px;
  right: -30px;
  width: 73px;
  height: 70px;
  /* animation: square1 13s ease infinite; */
  /* animation: square3 18s cubic-bezier(0.18, 0.18, 0.82, 0.78) infinite; */
  animation-direction: reverse !important;
}
@keyframes square3 {
  0%    {top: 93%; left: -5%}
  25%   {top: 0%; left: -5%;}
  50%   {top: 0%; left: 95%;}
  75%  {top: 93%; left: 95%;}
  100%    {top: 93%; left: -5%}
}
.presale_box_outer::before {
  bottom: -34px;
  right: -30px;
  background: url("../src/assets/images/platinum/banimg3.png") no-repeat center;
  width: 161px;
  height: 161px;
  /* animation: square1 13s ease infinite; */
  animation: square4 18s cubic-bezier(0.18, 0.18, 0.82, 0.78) infinite;
  animation: rotate 8s infinite linear !important;
}
@keyframes square4 {
  0%    {top: 90%; left: 90%}
  25%   {top: 90%; left: -5%;}
  50%   {top: -8%; left: -5%;}
  75%  {top: -8%; left: 90%;}
  100%    {top: 90%; left: 90%}
}
.presale_box_outer::after {
  bottom: -12px;
  left: 133px;
  background: url("../src/assets/images/platinum/banimg4.png") no-repeat center;
  width: 57px;
  height: 54px;
  /* animation: square1 13s ease infinite; */
  animation: square5 18s cubic-bezier(0.18, 0.18, 0.82, 0.78) infinite;
}
@keyframes square5 {
  0%    {top: 95%; left: 50%}
  20%   {top: 95%; left: 0%;}
  40%   {top: 0%; left: 0%;}
  60%  {top: 0%; left: 95%;}
  80%    {top: 95%; left: 95%}
  100%    {top: 95%; left: 50%}
}

.presale_box h2 {
  font-size: 48px;
  font-weight: 700;
}

.presale_box p {
  color: #707883;
}


section {
  padding: 60px 0;
  overflow: hidden;
}

.main_title {
  font-size: 48px;
  font-weight: 600;
  color: #fff;
  margin-bottom: 30px;
}

.h5tag {
  font-size: 24px;
  font-weight: 600;
  color: #fff;
}
.head{
  max-width: 75%;
  text-align: center;
  margin: 0 auto 15px;
}

.main_title span {
  color: #00a5ff;
}

.inner_para {
  font-size: 18px;
}
.features {
  padding: 0;
}
.features .main_title{
  margin-bottom: 40px;
}
.features .box {
  padding-left: 100px;
  margin: 30px 0;
}
.features .box p{
  color: #707883;
  margin-top: 15px;
}
.features .box h5 span{
display: block;
}
.features .img_box {
  position: relative;
  display: inline-block;
  margin-bottom: 15px;
}
.features .img_box::before {
  content: "";
  position: absolute;
  background: url("../src/assets/images/platinum/f_shade.png") no-repeat center;
  width: 254px;
  height: 271px;
  background-size: contain;
  z-index: -1;
  left: -50px;
  top: -61px;
}

.stacking {
  position: relative;
  overflow: visible;
}
.stacking::before {
  content: "";
  position: absolute;
  background: url("../src/assets/images/platinum/stack_shade.png") no-repeat center;
  width: 500px;
  height: 500px;
  background-size: contain;
  bottom: -120px;
  right: 0;
  z-index: -1;
}
.stacking .stack_box{
  background: linear-gradient(140deg,#0b0c0f, #21252b) padding-box, linear-gradient(to top, #af672d, #f0c96c) border-box;
  border: 5px solid transparent;
  border-radius: 10px;
  padding: 25px 15px;
  padding-left: 35px;
  max-width: 80%;
  margin: 0 auto;
}
.stacking .main_title{
 font-size: 38px;
 margin-bottom: 15px;
}
.stacking p{
 color: #707883;
}
.stacking .primary_btn{
  padding: 10px 40px;
}

.token .head{
  color: #707883;
}
.token .head .main_title{
  margin-bottom: 20px;
}
.token .box h4{
  font-weight: 600;
  font-size: 30px;
  color: #fff;
  margin-bottom: 25px;
}
.token .box p{
  color: #fbea78;
  font-weight: 500;
}
.token ul{
  padding-left: 0;
  margin: 0;
  list-style-type: none;
}
.token ul li{
  margin: 0;
}
.token ul li p{
  color: #fff !important;
  font-weight: 600 !important;
  margin-bottom: 2px;
}
.token ul li div{
  background: #151212;
  border: 3px solid #121418;
  border-radius: 5px;
  height: 36px;
  position: relative;
  z-index: 0;
  text-align: center;
  line-height: 33px;
  margin-bottom: 2px;
}
.token ul li div::before {
  content: "";
  position: absolute;
  left: 0;
  height: 115%;
  border-radius: 5px;
  z-index: -1;
  bottom: 0;
  top: 0;
  margin: auto;
}
.token ul li:nth-child(1) div::before {
  width: 65%;
  background: #14a0c1;
}
.token ul li:nth-child(2) div::before {
  width: 10%;
  background:#7de412;
}
.token ul li:nth-child(3) div::before {
  width: 20%;
  background: #4f7caa;
}
.token ul li:nth-child(4) div::before {
  width: 10%;
  background: #00cd99;
}

.referral{
  position: relative;
  overflow: visible;
}
.referral::before{
  content: "";
  position: absolute;
  background: url("../src/assets/images/platinum/refferal_bg.png") no-repeat center;
  width: 550px;
  height: 600px;
  background-size: contain;
  z-index: -1;
  top: -172px;
  right: 40px;
}
.referral h3{
 font-size: 38px;
 font-weight: 600;
 text-align: center;
 margin-bottom: 30px;
}
.referral h5{
  font-size: 30px;
  font-weight: 800;
 }
 .referral .row p{
   color: #fbea78;
 }
 .referral .box{
  text-align: center;
 }
 .referral .referral_box{
  background: linear-gradient(140deg,#0b0c0f, #21252b) padding-box, linear-gradient(to top, #af672d, #f0c96c) border-box;
  border: 2px solid transparent;
  border-radius: 10px;
  overflow: hidden;
  text-align: center;
  max-width: 80%;
  margin: 0 auto;
 }
 .referral .up_box{
  background: #101216;
  padding: 15px 30px;
 }
 .referral .blw_box{
  background: #0c0e12;
  padding: 10px 30px;
 }
 .referral .blw_box p{
   color: #707883;
 }

 .roadmap {
  position: relative;
  overflow: visible;
 }
 .roadmap::before {
  content: "";
  position: absolute;
  background: url("../src/assets/images/platinum/roadmap_bg.png") no-repeat center;
  bottom: -200px;
  right: 0;
  width: 500px;
  height: 500px;
  background-size: contain;
  z-index: -1;
 }
 .roadmap p{
  color: #fbea78;
  font-size: 18px;
  font-weight: 600;
 }

 .sidebar{
  background: url("../src/assets/images/platinum/sidebar_bg.png") no-repeat center;
  width: 200px;
  background-color: #bf4c4c00;
  background-size: cover;
  position: fixed;
  z-index: 100;
  left:  0;
  top: 0;
  bottom: 0;
  z-index: 1000;
 }

 .menu_icon{
  display: none;
  cursor: pointer;
 }
 .sidebar .primary_btn {
    font-size: 16px;
    padding: 5px;
    width: 90%;
    display: none;
    margin: 0 10px;
 }
 .close_icon{
  text-align: left;
  padding: 10px;
  color: #797f89;
  font-size: 20px;
  display: none;
  cursor: pointer;
}
 .sidebar .sidebar_logo{
  padding: 30px 10px;
  text-align: center;
 }
 .sidebar ul{
  list-style-type: none;
  padding-left: 0;
  margin-top: 80px;
 }
 .sidebar ul li{
  font-size: 18px;
  font-weight: 500;
  border-bottom: 1px solid #000000;
 }
 .sidebar ul li a{
  padding: 10px 0;
  padding-left: 25px;
  color: #707883;
  cursor: pointer;
  text-transform: uppercase;
 }
 .sidebar ul li a.active, .sidebar ul li a:hover{
  background: #151212;
  color: #fff;
  text-decoration: none;
 }

 .buytoken{
  margin-top: -86px;
 }
 .buytokenbg{
  background: url("../src/assets/images/platinum/buytoken_bg.png") no-repeat center;
  padding-bottom: 150px;
  padding-top: 150px;
  background-size:100% 100% ;
 }
 .buytoken .head{
  margin-bottom: 40px;
 }
 .buytoken .head p {
 color: #707883;
 }
 .buytoken .head_box{
  padding: 20px;
  background: linear-gradient(133deg, #0b0c0f 40%, #21252b);
 }
 .buytoken .head_box h5{
  font-size: 20px;
  font-weight: 400;
  margin-bottom: 3px;
 }
 .buytoken .head_box p {
  color: #676f79;
  font-size: 15px;
  margin-bottom: 0;
  }
 .buytoken .head_box p  span{
  color: #fbea78;
 }
 .buytoken .box {
  background: linear-gradient(132deg, #0b0c0f, #21252b);
  border-radius: 10px;
  overflow: hidden;
  border: 3px solid #2c3036;
}
.buytoken .box .primary_btn {
  padding: 10px 20px;
  font-size: 18px;
  border-radius: 30px;
  margin-bottom: 20px;
}
.buytoken  .box_ctn{
  padding: 30px;
  padding-bottom: 20px;
}
.buytoken  .box_ctn .label {
 display: flex;
 align-items: center;
 justify-content: space-between;
}
.buytoken  .box_ctn .label {
  font-size: 15px;
}
.buytoken  .box_ctn .label span{
  color: #fbea78;
}
.buytoken .blw_box{
  text-align: center;
}
.buytoken .blw_box p{
  margin-bottom: 10px;
}
.buytoken .blw_box p span{
  color: #fbea78;
}
.buytoken .blw_box p a{
  color: #fbea78;
  text-decoration: underline;
}


.buytoken .form-control{
  background: #060608;
  color:#fff;
  font-size: 15px;
  border: 1px solid #1a1d21;
  height: 52px;
  border-right: 0;
}
.buytoken .form-control::placeholder{
  color:#fff;
  font-size: 15px;
}
.buytoken .form-control:focus{
  box-shadow: none;
}
.buytoken .input-group-text{
  background: #060608;
  font-size: 15px;
  color:#fff;
  border: 0;
  height: 50px;
  margin: auto;
  /* padding-left: 15px;
  padding-right: 25px; */
  width: 67.9px;
}
input::-webkit-inner-spin-button,
input::-webkit-outer-spin-button {
    opacity: 0;
    -moz-opacity:0;
}
.buytoken.stake_token {
  margin: 0;
}
.buytoken.stake_token .box_ctn {
  padding: 0;
  padding-bottom: 20px;
}
 .buytoken  .number_wrapper {
  position: relative;
  width: 100%;
  display: block;
}
.stake_btn_grps {
  display: flex;
  align-items: center;
  justify-content: center;
}
.stake_btn_grps button+button,
.stakeamount_btn_grps button+button {
  margin-left: 10px;
}
.stake_btn_grps button.primary_btn {
  font-size: 16px;
  padding: 8px 25px;
}
.stakeamount_btn_grps button.primary_btn {
  margin-bottom: 0 !important;
  padding: 8px 10px !important;
  font-size: 16px !important;
  border-radius: 10px !important;
  width: 35px !important;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.stakeamount_btn_grps {
  display: flex;
  justify-content: flex-end;
  margin-top: -10px;
  margin-bottom: 15px;
}
 .buytoken  .number_wrapper:after,
 .buytoken  .number_wrapper:before {
  position: absolute;
  /* right: 81px; */
  pointer-events: none;
  background: transparent;
  z-index: 10;
  font-size: 11px;
  background: url("../src/assets/images/platinum/ban_num.png") no-repeat center;
  width: 20px;
  height: 20px;
  background: #060608;
  right: 77px;
}

 .buytoken  .number_wrapper:after {
  color:#676f79;
  content: "\25BC";
  top: 41%;
  top: 45%;

  /* background: url("../src/assets/images/platinum/ban_num.png") no-repeat center; */
}

 .buytoken  .number_wrapper:before, .buytoken  .number_wrapper:active::before,
 .buytoken  .number_wrapper:focus::before {
  color:#676f79;
  content: "\25B2";
  bottom: 41%;
}
.curve{
  width: 100%;
  height: 283px;
  background: #0b0c0f;
  border-radius: 0 0 50% 50%;
  /* background: aliceblue; */
  margin-bottom: -132px;
  z-index: 1;
  position: relative;
} 
.staking .main_title{
  margin-bottom: 20px;
}
.staking .head p{
  font-size: 18px;
  color: #707883;
}
.staking .head p.addr{
  color: #fbea78;
}
.staking .row{
  justify-content: center;
}
.staking .box{
  width: 100%;
  margin: 15px 0;
}
.staking .head_box{
  display: flex;
  align-items: center;
  width: 100%;
}
.staking .head_box_flx{
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 0 10px;
}
.staking .head_box_flx img{
  height: 50px;
}
.staking .box:hover{
  background: linear-gradient(132deg, #0b0c0f, #21252b) padding-box, linear-gradient( #af672d, #f0c96c) border-box;
  border: 3px solid transparent;
}
.staking ul{
  display: flex;
  justify-content: space-between;
  list-style: none;
  padding-left: 0;
  margin-bottom: 40px;
}
.staking ul li{
  color: #676f79;
  font-size: 15px;
}
.staking ul li.active{
  color: #f4e978;
  border-bottom: 1px solid #f4e978;
  padding-bottom: 5px;
}
.staking .box_ctn .label img{
 width: 25px;
 height: 25px;
}
.referral .invite_addr{
  font-size: 18px;
  color: #707883  ;
}
.referral .addr{
   color: #fbea78 !important;
   word-wrap: break-word;
}
.referral .refer_box p{
  color: #fbea78;
  font-size: 18px;
}
.referral .refer_box h5{
   font-weight: 800;
   font-size: 30px;
   color: #fff;
}
.referral .box .primary_btn {
  border-radius: 10px;
  padding: 8px 30px;
  margin-left: auto;
  display: block;
  margin-right: 25px;
}









.primary_btn {
  color: #030303 !important;
  font-size: 24px;
  font-weight: 700;
  text-transform: uppercase;
  padding: 18px 30px;
  transition: all 1s;
  border-radius: 10px;
  background: linear-gradient(90deg,
      #af672d,
      #f0c96c);
  border: 1px solid #c2964f;
  display: inline-block;
  cursor: pointer;
}
.primary_btn:hover {
  color: #030303;
  text-decoration: none !important;
}






.faq{
  background: #030404;
}
.faq_section{
  /* border-bottom-left-radius: 50%; */
  background: #0b0c0f;
  /* border-bottom-right-radius: 50%; */
  margin-top: 154px;
  padding-top: 20px;
  padding-bottom: 0px;
}

#faq_accodian .accordion-header .accordion-button {
  position: relative;
  display: flex;
  align-items: center;
  box-shadow: none;
  font-size: 20px;
  font-weight: 600;
  text-decoration: none;
  background: transparent;
  color: #fff;
  padding: 10px 15px 10px 30px;
  padding-left: 60px;
}

#faq_accodian .accordion-header,
#faq_accodian .accordion-body {
  background-color: transparent;
}

#faq_accodian .accordion-item {
  position: relative;
  padding: 15px 10px;
  background: #0b0c0f00;
  border: 0;
  border-bottom: 1px solid #1a1d21 !important;
  border-radius: 0;
  transition: 0.2s all;
  overflow: unset;
}

#faq_accodian .accordion-body {
  padding-top: 0;
  padding-left: 60px;
}

#faq_accodian .accordion-body p {
  font-size: 16px;
  margin-bottom: 15px;
  cursor: default;
  padding-left: 0px;
  color: #666e78;
}
#faq_accodian .accordion-body span{
  color: #fbea78;
}
#faq_accodian .accordion-body .step{
  font-size: 18px;
  color: #ffffff;
  font-weight: 500;
  display: inline-block;
}
#faq_accodian .accordion-button:not(.collapsed)::after {
  background: url("./assets/images/platinum/minus.png") no-repeat center;
  position: absolute;
  left: -6px;
}

#faq_accodian .accordion-button.collapsed::after {
  background: url("./assets/images/platinum/plus.png")  no-repeat center;
  width: 34px;
  height: 34px;
  position: absolute;
  left: -15px;
}

.footer {
  background-color: #030404;
  padding: 50px 0 30px;
  padding: 160px 0 30px;
}

.footer_panel_top {
  text-align: center;
  border-bottom: 0px solid #063554;
  padding-bottom: 20px;
}

.footer_panel_top h2 {
  font-size: 30px;
  font-weight: 600;
  color: #fff;
  margin-bottom: 20px;
}

.footer_panel_top p {
  font-size: 16px;
  max-width: 50%;
  margin: 0 auto 30px;
  color: #707883;
}

.footer_panel_top .input-group {
  width: 50%;
  margin: 0 auto 30px;
  border: 1px solid #1a1d21;
    border-radius: 10px;
    background: #060608;
    padding: 5px;
}

.footer_panel_top .input-group input {
  background: #060608;
  border: 0px solid #1a1d21;
  color: #ffffff;
  font-size: 18px;
  height: 65px;
  border-radius: 10px;
  padding-left: 30px;
  box-shadow: none !important;
  margin-right: 10px;
}

.footer_panel_top .input-group input::placeholder {
  color: #02d4ea;
  font-size: 18px;
}

.footer_panel_top .input-group-text {
  text-transform: uppercase;
  font-size: 24px;
  color: #030303;
  font-weight: 700;
  padding: 10px 30px;
  border-radius: 10px !important;
  background: linear-gradient(90deg, #af672d, #f0c96c);
  border: 1px solid #c2964f;
}

.footer_panel_top a {
  font-size: 16px;
  color: #e6007a;
  white-space: normal;
  word-break: break-all;
}

.footer_social_links {
  display: flex;
  justify-content: center;
  list-style: none;
  margin: 15px 0 0;
  padding: 0;
}

.footer_social_links li {
  margin: 0 10px;
}

.footer_social_links li a {
  width: 44px;
  height: 44px;
  background: #1b1b1f;
  border: 1px solid #1b1b1f;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #030404;
  font-size: 24px;
  text-decoration: none;
  transition: 0.2s all;
}

.footer_social_links li a:hover {
  background: linear-gradient(45deg, #af672d, #f0c96c);
  color: #030404;
  margin-top: -10px;
}

.footer_panel_bottom {
  padding-top: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
}

.footer_list_links {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  align-items: center;
}

.footer_list_links li {
  position: relative;
}

.footer_list_links li+li {
  margin-left: 30px;
}

.footer_list_links li::after {
  content: "";
  background: #fff;
  width: 1px;
  height: 15px;
  position: absolute;
  top: 5px;
  right: -15px;
}

.footer_list_links li:last-child::after {
  display: none;
}

.footer_list_links li a {
  color: #707883;
  font-size: 14px;
}

.footer_list_links li a:hover {
  text-decoration: none;
  color: #707883;
}

.footer_panel_bottom p {
  font-size: 14px;
  color: #707883;
  margin-bottom: 0;
}
.footer_panel_bottom p  span{
  color: #cf974c;
}
/* Connect Wallet */
.primary_modal .modal-content {
  background: linear-gradient(132deg, #0b0c0f, #21252b);
}

.primary_modal .modal-header {
  border-bottom: 1px solid #223137;
  padding: 20px 24px 15px;
}

.connect_wallet_button {
  border: 2px solid #223137;
  background-color: transparent;
  color: #111111;
  border-radius: 23px;
  padding: 0 24px;
  outline: 0;
  line-height: 46px;
  height: 52px;
  font-size: 18px;
  width: 100%;
  display: flex;
  align-items: center;
}
.primary_modal .btn-close{
  filter: invert(0%) sepia(2%) saturate(37%) hue-rotate(204deg) brightness(109%) contrast(101%);
}
.connect_wallet_button:hover {
  border-color: #877b43;
}

.primary_modal .modal-header h5 {
  font-size: 24px;
  font-weight: 500;
  margin: 0;
  color: #fff;
}

.connect_wallet_button img {
  margin-right: 15px;
}

.connect_wallet_button span {
  color: #fff;
  font-size: 22px;
  font-weight: 400;
}

.btn-close {
  width: 32px;
  height: 32px;
  color: #000;
  border: 0;
  background: transparent url("../src/assets/images/close_icon.png") no-repeat;
  opacity: 0.8;
  outline: none;
  box-shadow: none !important;
}

.btn-close:hover {
  opacity: 1;
}

.primary_modal a {
  text-decoration: none;
}

/* Inner Pages */
.inner_wrapper {
  min-height: 80vh;
  padding: 80px 0;
  position: relative;
  background: url("../src/assets/images/banner_bg.png") no-repeat center;
}

.title_flex {
  display: flex;
  text-align: center;
  margin-bottom: 30px;
  justify-content: space-between;
}

.buy_token_box {
  border: 1px solid #006a99;
  background: rgb(0, 31, 51);
  background: linear-gradient(0deg, rgba(0, 31, 51, 1) 0%, rgba(0, 46, 71, 1) 100%);
  border-radius: 30px;
  padding: 30px;
  position: relative;
}

.buy_token_box_inner,
.buy_token_box_outer {
  height: 100%;
  position: relative;
}

.buy_token_box_inner::before,
.buy_token_box_inner::after,
.buy_token_box_outer::before,
.buy_token_box_outer::after {
  content: "";
  background: url("../src/assets/images/presale_box_corner.png");
  width: 45px;
  height: 48px;
  position: absolute;
}

.buy_token_box_inner::before {
  top: -32px;
  left: -33px;
}

.buy_token_box_inner::after {
  top: -35px;
  right: -30px;
  transform: rotate(90deg);
}

.buy_token_box_outer::before {
  bottom: -34px;
  left: -30px;
  transform: rotate(-90deg);
}

.buy_token_box_outer::after {
  bottom: -32px;
  right: -33px;
  transform: rotate(180deg);
}

/* input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
} */

.buy_token_box form input.form-control {
  background: #001625;
  border: 1px solid #001625;
  color: #02d4ea;
  font-size: 18px;
  height: 50px;
  padding-left: 15px;
  box-shadow: none !important;
}

.buy_token_box form select.form-select {
  background: #001625 url("../src/assets/images/caret-down-fill.png") no-repeat right .75rem center;
  background-size: 13px 9px;
  border: 1px solid #001625;
  color: #02d4ea;
  font-size: 18px;
  height: 50px;
  padding-left: 15px;
  box-shadow: none !important;
}

.buy_token_box form label {
  color: #fff;
}

.custom_table .rdt_TableHead,
.custom_table .rdt_TableHeadRow,
.custom_table .rdt_Table>div {
  background-color: #00101a !important;
  color: #fff !important;
}

.custom_table .rdt_TableRow,
.custom_table .rdt_Pagination {
  color: #ffffff;
  background-color: #001624;
}

.custom_table .rdt_Pagination button {
  color: rgb(255 255 255 / 54%);
  fill: rgb(255 255 255 / 54%);
}

.custom_table .rdt_Pagination button:disabled {
  color: rgb(255 255 255 / 18%);
  fill: rgb(255 255 255 / 18%);
}
.box_ctn button.accordion-button,
.box_ctn .accordion-item {
  background: transparent;
  border: none;
  box-shadow: none;
  color: #fff;
  padding: 0;
}
.box_ctn .accordion-button::after {
  filter: invert(1);
}
.box_ctn .accordion-body {
  padding: 20px 0;
}
.box_ctn button.primary_btn.harvest_btn {
  font-size: 14px;
  margin: 0;
  padding: 6px 18px;
}
.text_yellow {
  color: #fbea78;
}
.hash_address {
  text-overflow: ellipsis;
  overflow: hidden;
  width: 150px;
  display: inline-block;    
  margin-left: 10px;
}



/* Media Queries */
@media screen and (max-width: 1500px) {
  .referral::before{

  }
}
@media screen and (max-width: 1399px) {
  .features .box{
    padding-left: 0;
  }
}
@media screen and (max-width: 1199px) {
  .main_title {
    font-size: 36px;
  }

  .primary_btn {
    font-size: 20px;
    padding: 16px 40px;
  }

  .page_header{
    margin-top: 0;
  }
  .page_header::after{
    width: 116px;
    height: 126px;
    right: 42px;
    background-size: contain;
  }
  .page_header::before{
    width: 131px;
    height: 131px;
    background-size: contain;
  }
  .presale_box_outer::before{
   width: 100px;
   background-size: contain;
  }
  .presale_box {
    margin-top: 50px;
  }
  .presale_box h2 {
    font-size: 31px;
  }

  .main_navbar {
    position: fixed;
    width: 100%;
    top: 0;
    z-index: 999;
  }
  .navbar_right li a.active::after,
  .navbar_right li a:hover::after {
    display: none;
  }

  .timer_time {
    font-size: 38px;
  }
  .buytoken {
    margin-top: 0;
  }
  .buytokenbg {
    border-radius: 0 !important;
  }

}

@media screen and (max-width: 991px) {

  .main_navbar{
    background-color: #0f1014 !important;
  }
  .faq_section{
    border-radius: 0;
  }
 
  .referral h3 {
    font-size: 28px;
  }
  .h5tag {
    font-size: 20px;
  }
  .sidebar ul{
    margin-top: 20px;
  }
  .sidebar .sidebar_logo{
    display: none;
  }
  .sidebar .primary_btn {
    display: block;
  }
  .sidebar{
    background: #0b0c0f;
    left: unset;
    right: 0;
    transition: .5s;
    right: -100%;
   }
   .menu_show{
     display: block !important  ;
     transition: .5s;
     right: 0;
   }
  .main_navbar {
    padding: 10px 0;
  }
  .main_navbar .navbar-brand{
    display: block;
    padding-left: 30px;
  }
  .footer_panel_top .input-group input{
    height: 50px;
  }
  .menu_icon,.close_icon{
    display: block;
   }
   .menu_icon {
    padding-right: 25px;
    font-size: 25px;
 }
  .navbar_right button.primary_btn{
    display: none;
  } 
  .mar_top_section {
    margin-top: 0;
    padding-top: 0;
    padding-bottom: 30px;
  }
  .footer_panel_top p {
    max-width: 100%;
  }

  .footer_panel_top .input-group {
    width: 100%;
  }

  .page_header h1 {
    margin: 15px 0 30px;
  }

  .page_header {
    text-align: center;
  }
  .timer_panel {
    justify-content: center;
  }
  .referral .box .primary_btn{
    margin-right: 0;
  }
  .referral .refer_box h5 {
    font-size: 25px;
  }
}

@media screen and (max-width: 767px) {
  .head{
    max-width: 100%;
  }
  .menu_icon{
    padding-right: 0;
  }
  .primary_btn:after, .primary_btn:before{
    display: none;
  }
  .main_navbar{
    padding-right: 30px;
    padding-left: 30px;
    background-color: #0f1014 !important;
  }
  .main_navbar .navbar-brand{
    padding-left: 0px;
  }
  .token .row{
    margin-top: -100px;
  }
  .page_header{
    overflow: hidden !important ;
    padding: 150px 0 100px;
  }
  .page_header::after{
    display: none;
  }
  .page_header::before {
    width: 94px;
    height: 94px;
}
  .navbar_right button.primary_btn{
    margin-right: 0;
  }
  .stacking .primary_btn{
    margin-bottom: 25px;
  }
  .stacking .main_title{
    font-size: 30px;
  }
  .roadmap_box,
  .roadmap_box:hover {
    min-height: auto;
  }

  .footer_panel_bottom {
    flex-wrap: wrap;
    justify-content: center;
    text-align: center;
  }

  .footer_list_links {
    margin-bottom: 10px;
  }

  .footer_panel_top .input-group-text {
    font-size: 16px;
    padding: 10px 18px;
  }

  .footer_panel_top h2 {
    font-size: 24px;
  }

  #faq_accodian .accordion-header .accordion-button {
    font-size: 18px;
  }

  #faq_accodian .accordion-body p,
  .footer_panel_top p,
  .roadmap_box .roadmap_box_content p {
    font-size: 14px;
  }

  .main_title {
    font-size: 30px;
  }

  .inner_para {
    font-size: 16px;
  }

  .page_header h1 {
    font-size: 28px;
    line-height: 36px;
  }

  .token_dis_div h2 {
    font-size: 24px;
  }

  .primary_btn {
    font-size: 16px;
    padding: 12px 40px;
  }

  .primary_btn:after,
  .primary_btn:before {
    content: " ";
    width: 30px;
    height: 30px;
  }
 
  .buytoken .box .primary_btn {
    font-size: 16px;
  }
  .referral .box .primary_btn{
    margin: 20px auto;
  }
  .curve{
    height: 183px;
    border-radius: 0;
  }
  .features{
    text-align: center;
  }
  .token{
    text-align: center;
  }
  .token ul{
    text-align: left;
  }
  .token img{
    margin-top: 15px;
  }
}

@media screen and (max-width: 575px) {
  .faq_section{
    border-radius: 0;
  }
  .presale_box_inner::before {
    top: -43px;
    left: -37px;
  }
  .presale_box::before{
    left: -20px;
    width: 41px;
    height: 21px;
    background-size:contain ;
  }
  .presale_box_inner::before,
.presale_box_inner::after,
.presale_box_outer::before,
.presale_box_outer::after {
  z-index: -1;
}
  .presale_box{
    padding: 30px;
  }
  .timer_panel>span {
    margin: 5px 5px 0 0;
  }

  span.timer_dots {
    font-size: 36px;
    line-height: 31px;
  }

  .timer_time {
    font-size: 20px;
    width: 38px;
    height: 38px;
  }

  span.timer_label {
    font-size: 11px;
  }


  .rotating_bonjour_logo {
    width: 150px;
  }

  .token_details>div {
    width: 100%;
    text-align: center;
  }

  .buytoken .box_ctn .label{
    flex-direction: column;
    align-items: flex-start;
  }
  .buytoken .box_ctn .label p{
    margin-bottom: 10px;
  }
}